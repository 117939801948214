<template>
  <b-tabs
    v-model="tabIndex"
    content-class="mt-1"
  >
    <!-- This tabs content will always be mounted -->
    <b-tab
      title="Historial de pedidos"
      lazy
    >
      <customer-orders-list
        @addOrderTab="newTab($event)"
      />
    </b-tab>

    <b-tab
      title="Nuevo pedido"
      lazy
    >
      <customer-orders-add
        @addOrderTab="newTab($event)"
      />
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown and will be un-mounted when hidden -->
    <b-tab
      v-for="order in ordersTabs.filter(order => order.type === 'view')"
      :key="order.id"
      :title="'Pedido #' + order.id"
      lazy
    >
      <customer-orders-view
        :order-id="order.id"
        @addEditTab="editTab(order.id)"
      />
    </b-tab>

    <b-tab
      v-for="order in ordersTabs.filter(order => order.type === 'edit')"
      :key="order.id"
      :title="'Edición pedido #' + order.id"
      lazy
    >
      <customer-orders-edit
        v-if="order.type === 'edit'"
        :order-id="order.id"
        @addOrderTab="newTab($event)"
      />
    </b-tab>

    <!-- Close tab button -->
    <template
      v-if="ordersTabs.length > 0"
      #tabs-end
    >
      <b-nav-item
        @click.prevent="closeTab(ordersTabs[0].id, ordersTabs[0].type)"
      >
        <b>x</b>
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import CustomerOrdersList from './CustomerOrdersList.vue'
import CustomerOrdersAdd from './CustomerOrdersAdd.vue'
import CustomerOrdersView from './CustomerOrdersView.vue'
import CustomerOrdersEdit from './CustomerOrdersEdit.vue'

export default {
  components: {
    CustomerOrdersList,
    CustomerOrdersAdd,
    CustomerOrdersView,
    CustomerOrdersEdit,
  },

  data() {
    return {
      tabIndex: 0,
      ordersTabs: [],
    }
  },

  methods: {
    newTab(orderId) {
      this.ordersTabs = []
      this.ordersTabs.push({
        id: orderId,
        type: 'view',
      })
      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    editTab(orderId) {
      this.ordersTabs = []
      this.ordersTabs.push({
        id: orderId,
        type: 'edit',
      })

      this.closeTab(orderId, 'view') // Close view tab before opening edit tab

      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    closeTab(orderId, type) {
      for (let i = 0; i < this.ordersTabs.length; i += 1) {
        if (this.ordersTabs[i].id === orderId && this.ordersTabs[i].type === type) {
          this.ordersTabs.splice(i, 1)
        }
      }
      this.tabIndex = 0 // Redirect to list component
    },
  },
}
</script>
