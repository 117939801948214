<template>
  <section class="invoice-add-wrapper">

    <validation-observer ref="simpleRules">
      <b-form
        v-if="show"
      >
        <b-row class="invoice-add">
          <b-col
            cols="12"
            md="5"
            xl="5"
          >
            <b-card
              class="order-add-wrapper"
              no-body
            >
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                    xl="12"
                  >
                    <h5 class="mb-1">
                      Información del cliente:
                    </h5>
                    <p
                      v-if="customerData.identidades"
                      style="font-size: 12px;"
                    >
                      <!-- Nombre -->
                      <span class="font-weight-bold">Nombre:</span>
                      {{ customerData.nombre }}
                      <br>
                      <!-- NIT -->
                      <span class="font-weight-bold">NIT:</span>
                      {{ customerData.nit }}
                      <br>
                      <!-- Dirección -->
                      <span class="font-weight-bold">Dirección:</span>
                      {{ (customerData.direccion) ? customerData.direccion : '' }}
                      <br>
                      <!-- Tipo de cliente -->
                      <span class="font-weight-bold">Tipo de cliente:</span>
                      {{ (customerData.tipo_desc) ? customerData.tipo_desc : '' }}
                      <br>
                      <!-- Lista de precios -->
                      <span class="font-weight-bold">Lista de precios:</span>
                      {{ (customerData.listap_desc) ? customerData.listap_desc : '' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="6"
                  >
                    <h5 class="mb-1">
                      Fecha de pedido:
                    </h5>
                    <p
                      v-if="customerData.identidades"
                      style="font-size: 12px;"
                    >
                      {{ customerOrderData.FechaPedidoCliente }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    xl="6"
                  >
                    <h6 class="mb-1 mt-1">
                      Colocado por:
                    </h6>
                    {{ customerOrderData.UsuarioNombre }}
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                    xl="12"
                  >
                    <span class="font-weight-bold">Observaciones: </span>
                    {{ (customerOrderData.Observaciones) ? customerOrderData.Observaciones : 'No hay observaciones' }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            md="7"
            xl="7"
          >
            <b-card
              class="order-add-wrapper"
              no-body
            >
              <p class="mb-1 mt-1 ml-2">
                <b>Detalle del pedido:</b>
              </p>
              <b-card-body class="form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <div
                    v-for="(product, index) in customerOrderData.products"
                    :key="index"
                    ref="row"
                    class="pb-1"
                  >
                    <div class="d-flex border rounded">
                      <b-col
                        v-if="product.label !== ''"
                        cols="4"
                        md="4"
                        xl="4"
                        class="col-items-customer-order"
                      >
                        {{ product.label }} - {{ product.description }}
                      </b-col>

                      <b-col
                        v-if="product.label === ''"
                        cols="4"
                        md="4"
                        xl="4"
                        class="col-items-customer-order"
                      >
                        <v-select
                          v-if="productsOptionsSelect"
                          input-id="select-products"
                          label="descripcion"
                          :options="productsOptionsSelect"
                          placeholder="Selecciona un producto"
                          style="font-size: 10px;"
                          @input="val => setProductData(index, val)"
                          @search="onSearchProductsSelect"
                        >
                          <template #option="option">
                            {{ option.label }} - {{ option.descripcion }}
                          </template>
                          <template slot="no-options">
                            No se encontraron productos
                          </template>
                        </v-select>
                      </b-col>

                      <b-col
                        cols="3"
                        md="3"
                        xl="3"
                        class="col-items-customer-order"
                      >
                        <b-form-input
                          :id="`product-price-${index}`"
                          v-model="product.price"
                          type="number"
                          min="0.01"
                          step="any"
                          size="sm"
                        />
                      </b-col>
                      <b-col
                        cols="2"
                        class="col-items-customer-order"
                      >
                        <b-form-input
                          v-model="product.quantity"
                          type="number"
                          min="1"
                          step="any"
                          size="sm"
                        />
                      </b-col>
                      <b-col
                        cols="2"
                        md="2"
                        xl="2"
                        class="col-items-customer-order"
                      >
                        <p
                          style="padding-top: 5px;"
                        >
                          <b>Q.{{ Number(product.price * product.quantity).toFixed(2) }}</b>
                        </p>
                      </b-col>
                      <b-col
                        cols="1"
                        md="1"
                        xl="1"
                      >
                        <div class="d-flex flex-column justify-content-between border-left">
                          <feather-icon
                            size="20"
                            icon="XIcon"
                            class="cursor-pointer"
                            style="padding-bottom: 5px;"
                            @click="removeItem(index)"
                          />

                          <feather-icon
                            :id="`form-item-settings-icon-${index}`"
                            size="20"
                            icon="SettingsIcon"
                            class="cursor-pointer"
                            style="padding-top: 5px;"
                            @click="getProductHistoryPrices(product.label)"
                          />

                          <b-popover
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group
                                    label="Historial de precios"
                                    :label-for="`setting-item-${index}-history-price-${index}`"
                                  >
                                    <b-table
                                      v-if="productHistoryPrices"
                                      empty-text="No hay ventas registradas"
                                      select-mode="range"
                                      sticky-header="true"
                                      small
                                      :items="productHistoryPrices"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="outline-secondary"
                                    @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                  >
                                    Cerrar
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <b-card-body>
                <b-row>
                  <!-- Add button -->
                  <b-button
                    size="sm"
                    variant="secondary"
                    class="mb-1 mt-1 ml-1"
                    @click="addBlankItem()"
                  >
                    Agregar producto
                  </b-button>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="10"
                  >
                    <div class="sale-total-wrapper">
                      <div
                        class="sale-total-item"
                      >
                        <p class="sale-total-title">
                          Total:
                        </p>
                        <p class="sale-total-amount">
                          Q.{{ customerOrderTotal }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <!-- Spacer -->
                <hr class="invoice-spacing">
              </b-card-body>

              <div class="m-2">
                <b-col
                  sm="12"
                  md="12"
                  xl="12"
                  style="float:right;"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    variant="warning"
                    block
                    :disabled="!customerOrderData.products.length || (!customerOrderData.identidades || customerOrderData.identidades === '')"
                    @click="updateData()"
                  >
                    Actualizar pedido
                  </b-button>
                </b-col>
              </div>
            </b-card>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </section>
</template>

<script>
import axios from '@axios'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  props: {
    orderId: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      // Sale
      customerOrderData: {
        identidades: '',
        fecha_pedido: this.getCurrentDate(),
        observaciones: '',
        products: [],
      },

      // Products
      productsOptions: [],
      productsOptionsSelect: [],
      productFields: [
        { key: 'label', label: 'Producto' },
        { key: 'descripcion', label: 'Descripción' },
        { key: 'precio', label: 'Precio' },
      ],
      selectedProducts: [],
      show: true,
      searchQuery: '',
      categoryOptions: [],
      selectedCategory: '',

      customerData: {},
      customerLP: '',

      // pagination
      perPage: 10,
      perPageProductsOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,
      productsMeta: null,

      sortField: '',
      sortDesc: 'desc',

      productHistoryPrices: [],

      tableIsBusy: true,
    }
  },

  computed: {
    customerOrderTotal() {
      let total = 0
      this.customerOrderData.products.forEach(element => {
        total += element.price * element.quantity
      })
      return Number(total).toFixed(2)
    },
  },

  mounted() {
    if (this.orderId > 0) {
      this.getCustomerOrderData()
      this.initTrHeight()
    }
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    getCustomerOrderData() {
      axios
        .get(`accounting-system/customer-order/${this.orderId}`)
        .then(response => {
          this.setCustomerData(response.data.data.IdCliente)

          this.customerOrderData.Observaciones = (response.data.data.Observaciones) ? response.data.data.Observaciones : '-'
          this.customerOrderData.SummaryInfo = response.data.data.SummaryInfo
          this.customerOrderData.FechaIngresoSistema = response.data.data.FechaIngresoSistema
          this.customerOrderData.FechaPedidoCliente = response.data.data.FechaPedidoCliente
          this.customerOrderData.UsuarioNombre = response.data.data.UsuarioNombre

          // llenar customerOrderData.products con info del Detalle
          response.data.data.Detalle.forEach(element => {
            this.customerOrderData.products.push({
              product_id: element.idproductos,
              label: element.producto,
              description: element.producto_desc,
              price: element.precio_pedido,
              quantity: element.cantidad,
            })
          })

          this.initTrHeight()
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    setCustomerData(customer) {
      if (customer) {
        // get especific customer
        axios
          .get(`accounting-system/entities/${customer}`)
          .then(response => {
            this.customerOrderData.identidades = response.data.data.identidades
            this.customerData = response.data.data
            this.customerLP = response.data.data.listap
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

    onSearchProductsSelect(search, loading) {
      this.productsOptionsSelect = []

      const filterArray = [
        { field: 'activo', value: 0 },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`accounting-system/product-price-list-detail/${this.customerLP}?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              response.data.data.forEach(element => {
                this.productsOptionsSelect.push({
                  id: element.idproductos,
                  label: element.producto,
                  precio: element.precio,
                  descripcion: element.descrip,
                })
              })
            })
            .catch(error => {
              loading(false)
              this.showErrors(error)
            })
        }, 500)
      }
    },

    onSearchProducts(search, loading) {
      this.productsOptions = []

      const priceList = this.customerLP
      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`accounting-system/product-price-list-detail/${priceList}?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              this.productsOptions = []
              response.data.data.forEach(element => {
                this.productsOptions.push({
                  label: element.name,
                  id: element.id,
                  cost: element.cost,
                  price: element.price,
                  stock: element.stock,
                  descripcion: element.description,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getProducts(this.customerLP)
      }
    },

    getProductHistoryPrices(productLabel) {
      axios
        .get(`accounting-system/product/hist?nit=${this.customerData.nit}&producto=${productLabel}`)
        .then(async response => {
          if (response) {
            this.productHistoryPrices = response.data
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getCategories() {
      axios
        .get('category?perPage=100&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.categoryOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchCategories(search, loading) {
      this.categoryOptions = []
      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`category?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.categoryOptions = []
              response.data.data.forEach(element => {
                this.categoryOptions.push({
                  label: element.name,
                  id: element.id,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCategories()
      }
    },

    updateData() {
      this.$bvModal
        .msgBoxConfirm('¿Deseas actualizar el pedido?', {
          title: 'Confirmar',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'SI',
          cancelTitle: 'NO',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`accounting-system/customer-order/${this.orderId}`, this.customerOrderData)
              .then(async response => {
                if (response.data.res) {
                  this.makeToast('success', 'Pedido actualizado', `Se ha actualizado el pedido #${response.data.data.Id} correctamente.`)
                  await new Promise(resolve => { window.setTimeout(resolve, 500) })
                  this.$emit('addOrderTab', parseInt(response.data.data.Id, 10))
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    // Métodos para manejar productos dinamicamente
    // =======================================================
    onRowSelected(items) {
      this.selectedProducts = items
      if (items[0]) {
        this.addItem()
      }
    },

    setProductData(index, val) {
      if (val === null) {
        this.customerOrderData.products[index].product_id = 0
        this.customerOrderData.products[index].label = ''
        this.customerOrderData.products[index].description = ''
        this.customerOrderData.products[index].price = 0
        this.customerOrderData.products[index].quantity = 1
      } else {
        const foundItem = this.customerOrderData.products.find(element => element.product_id === val.id)
        if (foundItem) {
          this.makeToast('danger', 'Producto ya seleccionado', `El producto ${val.descripcion} ya fue seleccionado anteriormente.`)
          this.removeItem(index)
        } else {
          this.customerOrderData.products[index].product_id = val.id
          this.customerOrderData.products[index].label = val.label
          this.customerOrderData.products[index].description = val.descripcion
          this.customerOrderData.products[index].price = val.precio
          this.customerOrderData.products[index].quantity = 1
        }
      }
    },

    addItem() {
      this.$refs.form.style.overflow = 'hidden'

      const foundItem = this.customerOrderData.products.find(element => element.product_id === this.selectedProducts[0].id)
      if (foundItem) {
        this.makeToast('danger', 'Producto ya seleccionado', `El producto ${this.selectedProducts[0].label} ya fue seleccionado anteriormente.`)
      } else {
        this.customerOrderData.products.push({
          label: this.selectedProducts[0].label, // P0123
          product_id: this.selectedProducts[0].id,
          price: Number(this.selectedProducts[0].precio).toFixed(2),
          quantity: 1,
        })

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      }
    },

    removeItem(index) {
      this.customerOrderData.products.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    addBlankItem() {
      this.customerOrderData.products.push({
        label: '',
        product_id: 0,
        price: 0,
        quantity: 0,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    // =======================================================
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.order-add-wrapper {
  .add-new-customer-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.sale-total-wrapper {
  width: 100%;
  max-width: 10rem;
  .sale-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    .sale-total-title {
      margin-bottom: 0.35rem;
    }
    .sale-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.per-page-selector {
  width: 90px;
}
.col-items-customer-order {
  @media (max-width: 600px) {
    font-size: 10px;
  }

  padding-top: 9px;
  padding-bottom: 0px;
  padding-right: 2px;
  padding-left: 1px;
}
</style>
